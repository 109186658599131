<template>
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
      :style="{ borderTop: `2px solid ${primaryColor}` }"
    >
      <section class="text-center col-lg-8 col-sm-12">
        <HeadImage
          :image="article.metadata.media.imgix_url"
          :title="article.title"
          :caption="article.metadata.caption"
          :color="primaryColor"
        />
        <hr />

        <section
          class="text-left"
          style="
            color: #cacaca !important;
            margin-bottom: 1px;
            margin-top: 3px;
            padding-right: 20px;
            padding-left: 20px;
          "
        >
          <div class="card" style="border-bottom: 1px solid black">
            <div class="card">
              <small
                ><i
                  class="tim-icons icon-watch-time"
                  :style="{
                    color: secondaryColor,
                    borderRadius: '100px',
                    padding: '5px',
                  }"
                ></i>
                READING TIME: {{ readingTime }}</small
              >
              <small
                ><i
                  class="tim-icons icon-calendar-60"
                  :style="{
                    color: secondaryColor,
                    borderRadius: '100px',
                    padding: '5px',
                  }"
                ></i>
                CREATED:
                {{ new Date(article.created_at).toDateString() }}</small
              >
              <small
                ><i
                  class="tim-icons icon-pencil"
                  :style="{
                    color: secondaryColor,
                    borderRadius: '100px',
                    padding: '5px',
                  }"
                ></i>
                LAST MODIFIED:
                {{ new Date(article.published_at).toDateString() }}
              </small>
            </div>

            <h5 class="section-header">
              <i
                class="tim-icons icon-light-3"
                style="font-size: 12px"
                :style="{
                  color: secondaryColor,
                  border: `2px solid ${primaryColor}`,
                  borderRadius: '100px',
                  padding: '5px',
                }"
              ></i>
              SUMMARY
            </h5>
            <div
              class="text-left"
              v-html="article.metadata.summary"
              style="padding: 10px"
            ></div>
          </div>
          <div v-html="this.articleContent"></div>
          <!--          <v-runtime-template :template="this.articleContent"></v-runtime-template>-->
          <br>
          <div class="card centered text-center" style="padding: 60px">

            <img
              v-lazy="djImage + '?format=auto&fit=clamp&h=60&w=60'"
              alt="los cabos dj"
              height="60px"
              width="60px"
              class="centered"
            />
            <small style="margin-top: 10px">LOS CABOS, MX</small>
            <br />
            <small>
              Being in the majority often means it's time to step back and
              reconsider.
            </small>
          </div>
          <SmartMenu withRsvp="false" :links="links">
            <div style="padding: 20px">
              <p>Unlock Los Cabos Magic
                <br>Secure your spot with us now!</p>
            </div>

          </SmartMenu>
        </section>
      </section>

      <aside class="card col-sm-4 col-lg-4 d-none d-lg-inline">
        <SmartMenu>

          <p><strong>Feeling Lost?</strong></p>
          <hr />
          <p>
            We know every detail of the scene. Let’s craft your ideal night out.
          </p>
          <hr />
        </SmartMenu>
      </aside>
    </article>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import SmartMenu from '@/components/SmartMenu/SmartMenu';
import HeadImage from '@/components/HeadImage/HeadImage';
export default {
  name: 'Article',
  props: ['slug'],
  components: {
    HeadImage,
    SmartMenu,
  },
  metaInfo() {
    return {
      title: `${this.article.title}. ${this.article.metadata.caption}`,
      // link: eventImage,
      meta: [
        {
          name: 'description',
          content:
            'Discover the vibrant nightlife of Los Cabos! Explore top clubs, live music venues, and exclusive events, ' +
            'from electronic music festivals to laid-back beach parties. Get insider tips, reviews, and guides to the best after-dark experiences in Cabo.',
        },
        {
          name: 'keywords',
          content:
            'Los Cabos nightlife, party articles, festival insights, nightlife blog, electronic music scene, DJ events, beach parties, Los Cabos after-dark guide, house & techno music, nightlife experiences, event reviews, nightlife trends, party tips, club recommendations',
        },
        {
          property: 'og:title',
          content:
            'Explore Los Cabos Nightlife: In-Depth Articles on Parties, Clubs & Festivals',
        },
        {
          property: 'og:url',
          content: `https://cabo.party/article/${this.slug}`,
        },
        {
          property: 'og:description',
          content: this.article.description,
        },
        {
          property: 'og:type',
          content: 'article',
        },
        {
          property: 'og:image',
          content: this.currentArticleImage,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('getArticle', { slug: this.slug });
    this.$store.dispatch('getLinks', { slug: 'article' });
    this.photos = 'article-general-images';
  },
  data: () => {
    return {
      primaryColor: 'rgb(84,158,211)',
      secondaryColor: '#7a8382',
      instagramImage:
        'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png',
      djImage:
        'https://imgix.cosmicjs.com/ec030ce0-f3e4-11ee-b555-0d0678c27dd7-Orthodox-priest-with-white-beard-listening-to-musi.jpg?w=250&amp',
    };
  },
  computed: mapState({
    articleContent: (state) => {
      return `<div>${state.article.metadata.content}</div>`;
    },
    article: (state) => state.article,
    links: (state) => state.links || [],
    currentArticleImage: (state) =>
      state.article.metadata.content && state.article.metadata.content.imgix_url,
    readingTime: (state) => {
      const wpm = 225;
      const words = state.article.metadata.content.trim().split(/\s+/).length;
      return Math.ceil(words / wpm) + ' mins';
    },
  }),
};
</script>


