var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('article',{staticClass:"row",staticStyle:{"margin-top":"10px","background":"linear-gradient(#1d1d1d, black)"},style:({ borderTop: `2px solid ${_vm.primaryColor}` })},[_c('section',{staticClass:"text-center col-lg-8 col-sm-12"},[_c('HeadImage',{attrs:{"image":_vm.article.metadata.media.imgix_url,"title":_vm.article.title,"caption":_vm.article.metadata.caption,"color":_vm.primaryColor}}),_c('hr'),_c('section',{staticClass:"text-left",staticStyle:{"color":"#cacaca !important","margin-bottom":"1px","margin-top":"3px","padding-right":"20px","padding-left":"20px"}},[_c('div',{staticClass:"card",staticStyle:{"border-bottom":"1px solid black"}},[_c('div',{staticClass:"card"},[_c('small',[_c('i',{staticClass:"tim-icons icon-watch-time",style:({
                  color: _vm.secondaryColor,
                  borderRadius: '100px',
                  padding: '5px',
                })}),_vm._v(" READING TIME: "+_vm._s(_vm.readingTime))]),_c('small',[_c('i',{staticClass:"tim-icons icon-calendar-60",style:({
                  color: _vm.secondaryColor,
                  borderRadius: '100px',
                  padding: '5px',
                })}),_vm._v(" CREATED: "+_vm._s(new Date(_vm.article.created_at).toDateString()))]),_c('small',[_c('i',{staticClass:"tim-icons icon-pencil",style:({
                  color: _vm.secondaryColor,
                  borderRadius: '100px',
                  padding: '5px',
                })}),_vm._v(" LAST MODIFIED: "+_vm._s(new Date(_vm.article.published_at).toDateString())+" ")])]),_c('h5',{staticClass:"section-header"},[_c('i',{staticClass:"tim-icons icon-light-3",staticStyle:{"font-size":"12px"},style:({
                color: _vm.secondaryColor,
                border: `2px solid ${_vm.primaryColor}`,
                borderRadius: '100px',
                padding: '5px',
              })}),_vm._v(" SUMMARY ")]),_c('div',{staticClass:"text-left",staticStyle:{"padding":"10px"},domProps:{"innerHTML":_vm._s(_vm.article.metadata.summary)}})]),_c('div',{domProps:{"innerHTML":_vm._s(this.articleContent)}}),_c('br'),_c('div',{staticClass:"card centered text-center",staticStyle:{"padding":"60px"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.djImage + '?format=auto&fit=clamp&h=60&w=60'),expression:"djImage + '?format=auto&fit=clamp&h=60&w=60'"}],staticClass:"centered",attrs:{"alt":"los cabos dj","height":"60px","width":"60px"}}),_c('small',{staticStyle:{"margin-top":"10px"}},[_vm._v("LOS CABOS, MX")]),_c('br'),_c('small',[_vm._v(" Being in the majority often means it's time to step back and reconsider. ")])]),_c('SmartMenu',{attrs:{"withRsvp":"false","links":_vm.links}},[_c('div',{staticStyle:{"padding":"20px"}},[_c('p',[_vm._v("Unlock Los Cabos Magic "),_c('br'),_vm._v("Secure your spot with us now!")])])])],1)],1),_c('aside',{staticClass:"card col-sm-4 col-lg-4 d-none d-lg-inline"},[_c('SmartMenu',[_c('p',[_c('strong',[_vm._v("Feeling Lost?")])]),_c('hr'),_c('p',[_vm._v(" We know every detail of the scene. Let’s craft your ideal night out. ")]),_c('hr')])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }